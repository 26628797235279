/* eslint-env jquery */

"use strict";

const gtm = require("../../gtm");

window.addEventListener("load", () => {
    const Swiper = require("swiper").default;
    const Navigation = require("swiper").Navigation;
    const Pagination = require("swiper").Pagination;
    const Scrollbar = require("swiper").Scrollbar;

    if (!window.swiperProductsCarouselInitialized) {
        const carousels = $(".js-swiper-products-carousel");

        carousels.each(function () {
            var eventWasTriggered = false;
            const $swiperWrapper = $(this);
            const swipperWrapperClass = `.swipper-${$swiperWrapper.data("id")}`;

            let slidesToShow, slidesToShowMobile;

            try {
                slidesToShow = parseInt($swiperWrapper.data("slides-to-show"), 10) || 5;
                slidesToShowMobile = parseInt($swiperWrapper.data("slides-to-show-mobile"), 10) || 2;
            } catch (e) {
                slidesToShow = 5;
                slidesToShowMobile = 2;
            }

            const loopEnabled = $swiperWrapper.data("loop-enabled");
            const navEnabled = $swiperWrapper.data("navigation-enabled");
            const paginationEnabled = $swiperWrapper.data("pagination-enabled");
            const scrollbarEnabled = $swiperWrapper.data("scroll-enabled");
            const carouselModules = [];

            if (navEnabled) {
                carouselModules.push(Navigation);
            }

            if (paginationEnabled) {
                carouselModules.push(Pagination);
            }

            if (scrollbarEnabled) {
                carouselModules.push(Scrollbar);
            }

            new Swiper(`${swipperWrapperClass} .js-swiper-container`, {
                loop: Boolean(loopEnabled),
                modules: carouselModules,
                pagination: {
                    el: `${swipperWrapperClass} .js-swiper-pagination`,
                    type: "bullets",
                    clickable: true,
                },
                navigation: {
                    nextEl: `${swipperWrapperClass} .js-swiper-button-next`,
                    prevEl: `${swipperWrapperClass} .js-swiper-button-prev`,
                },
                scrollbar: {
                    el: `${swipperWrapperClass} .js-swiper-scrollbar`,
                },
                slidesPerView: slidesToShowMobile,
                spaceBetween: 16,
                breakpoints: {
                    1024: {
                        slidesPerView: slidesToShow,
                        spaceBetween: 16,
                    },
                },
            });

            $(window).on("scroll", function () {
                if (!eventWasTriggered && $swiperWrapper.is(":visible") && $swiperWrapper.find(".product-tile").length) {
                    var windowHeight  = $(window).height();
                    var scrollTop     = $(window).scrollTop();
                    var elementOffset = $swiperWrapper.offset().top;

                    if (scrollTop + windowHeight > elementOffset) {
                        // Run tracking function when the element is on the screen
                        gtm.viewItemList($swiperWrapper);
                        eventWasTriggered = true;
                    }
                }
            });
        });

        window.swiperProductsCarouselInitialized = true;
    }
});
