let currencyCode = $(".gtm-data").attr("data-currency");

// triggers page_view event
function pageViewEvent() {
    const $gtmData = $(".gtm-data");
    let userType = $gtmData.attr("data-customer") === "true" ? "Registered" : "Guest";
    let language = $gtmData.attr("data-language").toUpperCase();
    let pageTitle = document.title;
    let pageLocation = window.location.href;
    let userID = $gtmData.attr("data-customer-id") ? $gtmData.attr("data-customer-id") : "";
    window.dataLayer &&
    window.dataLayer.push({
        "event": "page_view",
        "user_id": userID,
        "user_type": userType,
        "site_language": language,
        "page_title": pageTitle,
        "page_location": pageLocation
    });
}

// triggers select_item event
function selectItem(element) {
    const $productTile = $(element);
    let productID = $productTile.attr("data-pid");
    let productBrand = $productTile.find(".product-tile-brand .link").text().trim();
    let productName = $productTile.find(".pdp-link .link").text().trim();
    let productPrice = Number($productTile.find(".price .value").text().trim().substring(1).replace(".", ""));
    let productCategoryList = $productTile.attr("data-categories").split(",");

    let item = {
        "item_name": productName,
        "item_id": productID,
        "price": productPrice,
        "item_brand": productBrand,
        "quantity": 1,
        "index": 0
    };

    let newItem = appendCategories(item, productCategoryList);

    window.dataLayer &&
    window.dataLayer.push({
        "event": "select_item",
        "ecommerce": {
            "currency": currencyCode,
            "value": productPrice,
            "items": [newItem]
        }
    });
}

// triggers view_item event
function viewItem() {
    const $product = $(".product-detail");
    const $productDetails = $product.find(".product-details-section");
    let productID = $product.attr("data-pid");
    let productBrand = $productDetails.find(".brand").text().trim() || $productDetails.find(".manufacturer-name").text().trim();
    let productName = $productDetails.find(".product-name").text().trim();
    let productPrice = Number($productDetails.find(".sales .value").text().trim().substring(1).replace(".", ""));
    let productCategoryList = $productDetails.find(".gtm-categories").text().trim().split(",");
    let item = {
        "item_name": productName,
        "item_id": productID,
        "price": productPrice,
        "item_brand": productBrand,
        "quantity": 1,
        "index": 0
    };

    let newItem = appendCategories(item, productCategoryList);

    window.dataLayer &&
    window.dataLayer.push({
        "event": "view_item",
        "ecommerce": {
            "currency": currencyCode,
            "value": productPrice,
            "items": [newItem]
        }
    });
}

// triggers view_item_list event
function viewItemList(element, startValue) {
    const $productTile = startValue ? $(element).find(".product-tile:gt(" + startValue + ")") : $(element).find(".product-tile");
    const batchSize = 20;
    var objects = [];

    $productTile.each(function (index) {
        let productID = $(this).attr("data-pid");
        let productBrand = $(this).find(".product-tile-brand .link").text().trim();
        let productName = $(this).find(".pdp-link .link").text().trim();
        let productPrice = Number($(this).find(".price .value").first().text().trim().substring(1).replace(".", ""));

        const item = {
            "item_name": productName,
            "item_id": productID,
            "price": productPrice,
            "brand": productBrand,
            "quantity": 1,
            "index": index
        };

        let productCategoryList = $(this).attr("data-categories").split(",");
        let newItem = appendCategories(item, productCategoryList);

        objects.push(newItem);

        if (objects.length === batchSize) {
            window.dataLayer.push({ ecommerce: null });
            window.dataLayer.push({
                "event": "view_item_list",
                "ecommerce": {
                    "currency": currencyCode,
                    "items": objects
                }
            });
            objects = [];
        }
    });

    if (objects.length > 0) {
        window.dataLayer &&
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
            "event": "view_item_list",
            "ecommerce": {
                "currency": currencyCode,
                "items": objects
            }
        });
    }
}

// triggers add_to_cart event
function addToCart(element, productType, quantity, isMinicart) {
    const $product = $(element);
    const $productDetails = $product.find(".product-details-section");
    let productID, productBrand, productName, productPrice, productQuantity, productCategoryList, totalValue;
    switch (productType) {
        case "ahumadaFamilyProductPromotion":
            productID = $product.data("data-pid");
            productBrand = $product.data("product-brand");
            productName = $product.data("product-name");
            productPrice = Number($product.data("product-price"));
            productQuantity = 1;
            productCategoryList = $product.data("categories").split(",");
            totalValue = productPrice;
            break;
        case "tile":
            productID = $product.attr("data-pid");
            productBrand = $product.find(".product-tile-brand .link").text().trim();
            productName = $product.find(".pdp-link .link").text().trim();
            productPrice = Number($product.find(".price .value").text().trim().substring(1).replace(".", ""));
            productQuantity = 1;
            productCategoryList = $product.attr("data-categories").split(",");
            totalValue = productPrice;
            break;
        case "detail":
            productID = $product.attr("data-pid");
            productBrand = $productDetails.find(".brand").text().trim() || $productDetails.find(".manufacturer-name").text().trim();
            productName = $productDetails.find(".product-name").text().trim();
            productPrice = Number($productDetails.find(".sales .value").text().trim().substring(1).replace(".", ""));
            productQuantity = Number($productDetails.find(".quantity-select").val());
            productCategoryList = $productDetails.find(".gtm-categories").text().trim().split(",");
            totalValue = productPrice * productQuantity;
            break;
        case "cart":
            if (isMinicart) {
                productID = $product.attr("data-pid");
                productBrand = $product.attr("data-brand");
                productName = $product.find(".line-item-name span").text().trim();
                productPrice = Number($product.find(".sales .value").text().trim().substring(1).replace(".", ""));
                productCategoryList = $product.attr("data-categories").split(",");
            } else {
                productID = $product.attr("data-pid");
                productBrand = $product.attr("data-brand");
                productName = $product.find(".line-item-product-name").text().trim();
                productPrice = Number($product.find(".sales .value").text().trim().substring(1).replace(".", ""));
                productCategoryList = $product.attr("data-categories").split(",");
            }
            productQuantity = Number(quantity);
            totalValue = productPrice * productQuantity;
            break;
        default:
            break;
    }
    let item = {
        "item_name": productName,
        "item_id": productID,
        "price": productPrice,
        "item_brand": productBrand,
        "quantity": productQuantity,
        "index": 0
    };

    let newItem = appendCategories(item, productCategoryList);

    window.dataLayer &&
    window.dataLayer.push({
        "event": "add_to_cart",
        "ecommerce": {
            "currency": currencyCode,
            "value": totalValue,
            "items": [newItem]
        }
    });
}

// triggers add_to_cart event
function removeFromCart(element, quantity, isMinicart) {
    const $product = $(element);
    let productID, productBrand, productName, productPrice, productQuantity, productCategoryList, totalValue;
    if (isMinicart) {
        productID = $product.attr("data-pid");
        productBrand = $product.attr("data-brand");
        productName = $product.find(".line-item-name span").text().trim();
        productPrice = Number($product.find(".sales .value").text().trim().substring(1).replace(".", ""));
        productCategoryList = $product.attr("data-categories").split(",");
    } else {
        productID = $product.attr("data-pid");
        productBrand = $product.attr("data-brand");
        productName = $product.find(".line-item-product-name").text().trim();
        productPrice = Number($product.find(".sales .value").text().trim().substring(1).replace(".", ""));
        productCategoryList = $product.attr("data-categories").split(",");
    }
    productQuantity = Number(quantity);
    totalValue = productPrice * productQuantity;

    let item = {
        "item_name": productName,
        "item_id": productID,
        "price": productPrice,
        "item_brand": productBrand,
        "quantity": productQuantity,
        "index": 0
    };

    let newItem = appendCategories(item, productCategoryList);

    window.dataLayer &&
    window.dataLayer.push({
        "event": "remove_from_cart",
        "ecommerce": {
            "currency": currencyCode,
            "value": totalValue,
            "items": [newItem]
        }
    });
}

// triggers view_cart event
function viewCart() {
    const $products = $(".product-info");
    const basketValue = Number($(".grand-total").text().trim().substring(1).replace(".", ""));
    var objects = [];

    if ($products.length) {
        $products.each(function (index) {
            let productID = $(this).attr("data-pid");
            let productBrand = $(this).attr("data-brand");
            let productName = $(this).find(".line-item-product-name").text().trim();
            let productPrice = Number($(this).find(".sales .value").text().trim().substring(1).replace(".", ""));
            let productCategoryList = $(this).attr("data-categories").split(",");
            let productQuantity = Number($(this).find(".quantity.custom-select").val());

            const item = {
                "item_name": productName,
                "item_id": productID,
                "price": productPrice,
                "brand": productBrand,
                "quantity": productQuantity,
                "index": index
            };

            let newItem = appendCategories(item, productCategoryList);
            objects.push(newItem);
        });

        window.dataLayer &&
        window.dataLayer.push({
            "event": "view_cart",
            "ecommerce": {
                "currency": currencyCode,
                "value": basketValue,
                "items": objects
            }
        });
    }
}

// Used to trigger begin_checkout, add_shipping_info and add_payment_info event
function checkoutEvents(label) {
    const $products = $(".product-summary-block .product-line-item");
    const basketValue = Number($(".grand-total-sum").first().text().trim().substring(1).replace(".", ""));
    var objects = [];

    $products.each(function (index) {
        let productID = $(this).attr("data-pid");
        let productBrand = $(this).attr("data-brand");
        let productName = $(this).find(".line-item-name span").text().trim();
        let productPrice = Number($(this).find(".sales .value").text().trim().substring(1).replace(".", "") || $(this).find(".price .value").text().trim().substring(1).replace(".", ""));
        let productCategoryList = $(this).attr("data-categories").split(",");
        let productQuantity = Number($(this).find(".qty-card-quantity-count").text().trim());

        const item = {
            "item_name": productName,
            "item_id": productID,
            "price": productPrice,
            "brand": productBrand,
            "quantity": productQuantity,
            "index": index
        };

        let newItem = appendCategories(item, productCategoryList);
        objects.push(newItem);
    });

    var ecommerceObject = {
        "currency": currencyCode,
        "value": basketValue,
        "items": objects
    };

    switch (label) {
        case "add_shipping_info":
            ecommerceObject["shipping_tier"] = $(".shipping-method-block .delivery-type-container.active").first().find(".custom-control-label").text().trim();
            break;
        case "add_payment_info":
            ecommerceObject["payment_type"] = $(".ahumada-custom-radio.selected").first().closest(".payment-methos-accordion-header").attr("id");
            break;
        case "purchase":
            ecommerceObject["transaction_id"] = $(".summary-details.order-number").text().trim();
            ecommerceObject["shipping"] = $(".shipping-method-title").text().trim();
            ecommerceObject["payment"] = $(".credit-card-type span").text().trim();
            break;
        default:
            break;
    }

    window.dataLayer &&
    window.dataLayer.push({
        "event": label,
        "ecommerce": ecommerceObject
    });
}

// triggers sign_up event
function gtmSignUp() {
    window.dataLayer &&
    window.dataLayer.push({
        "event": "sign_up",
        "method": "Email"
    });
}

// triggers sign_up event
function gtmLogin() {
    window.dataLayer &&
    window.dataLayer.push({
        "event": "login",
        "method": "Email"
    });
}

// triggers search event
function gtmSearch() {
    const resultsCount = $(".search-result-count").attr("data-results-count");
    const searchQuery = $(".search-result-for").attr("data-search-query");

    window.dataLayer &&
    window.dataLayer.push({
        "event": "search",
        "search_term": searchQuery,
        "search_results": Number(resultsCount)
    });
}

// adds the categories to the item object
function appendCategories(item, productCategoryList) {
    for (let j = 0; j < productCategoryList.length; j++) {
        if (j === 0) {
            item.item_category = productCategoryList[j];
        } else {
            item["item_category" + (j + 1)] = productCategoryList[j].replace(/'/g, "");
        }
    }

    return item;
}

module.exports.pageViewEvent = pageViewEvent;
module.exports.selectItem = selectItem;
module.exports.viewItem = viewItem;
module.exports.viewItemList = viewItemList;
module.exports.addToCart = addToCart;
module.exports.viewCart = viewCart;
module.exports.removeFromCart = removeFromCart;
module.exports.gtmSearch = gtmSearch;
module.exports.gtmSignUp = gtmSignUp;
module.exports.gtmLogin = gtmLogin;
module.exports.checkoutEvents = checkoutEvents;
